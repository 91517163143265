@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
}

// Animations
.animate-fadein {
  -webkit-animation-name: fadein; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s; /* Safari 4.0 - 8.0 */
  animation-name: fadein;
  animation-duration: 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// Backgrounds
.background-image__login {
  background-color: #2196f3;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='600' height='100' viewBox='0 0 600 100'%3E%3Cg stroke='%23FFF' stroke-width='0' stroke-miterlimit='10' %3E%3Ccircle fill='%2390caf9' cx='0' cy='0' r='50'/%3E%3Ccircle fill='%2342a5f5' cx='100' cy='0' r='50'/%3E%3Ccircle fill='%231e88e5' cx='200' cy='0' r='50'/%3E%3Ccircle fill='%231565c0' cx='300' cy='0' r='50'/%3E%3Ccircle fill='%230d47a1' cx='400' cy='0' r='50'/%3E%3Ccircle fill='%23006EB4' cx='500' cy='0' r='50'/%3E%3Ccircle fill='%2390caf9' cx='600' cy='0' r='50'/%3E%3Ccircle cx='-50' cy='50' r='50'/%3E%3Ccircle fill='%236cb8f7' cx='50' cy='50' r='50'/%3E%3Ccircle fill='%233097ed' cx='150' cy='50' r='50'/%3E%3Ccircle fill='%232196f3' cx='250' cy='50' r='50'/%3E%3Ccircle fill='%231056b1' cx='350' cy='50' r='50'/%3E%3Ccircle fill='%23005bac' cx='450' cy='50' r='50'/%3E%3Ccircle fill='%23579bd6' cx='550' cy='50' r='50'/%3E%3Ccircle cx='650' cy='50' r='50'/%3E%3Ccircle fill='%2390caf9' cx='0' cy='100' r='50'/%3E%3Ccircle fill='%2342a5f5' cx='100' cy='100' r='50'/%3E%3Ccircle fill='%231e88e5' cx='200' cy='100' r='50'/%3E%3Ccircle fill='%231565c0' cx='300' cy='100' r='50'/%3E%3Ccircle fill='%230d47a1' cx='400' cy='100' r='50'/%3E%3Ccircle fill='%23006EB4' cx='500' cy='100' r='50'/%3E%3Ccircle fill='%2390caf9' cx='600' cy='100' r='50'/%3E%3Ccircle cx='50' cy='150' r='50'/%3E%3Ccircle cx='150' cy='150' r='50'/%3E%3Ccircle cx='250' cy='150' r='50'/%3E%3Ccircle cx='350' cy='150' r='50'/%3E%3Ccircle cx='450' cy='150' r='50'/%3E%3Ccircle cx='550' cy='150' r='50'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: 48%;
}

/* Text align */
$text-align: center, left, right;
@each $align in $text-align {
  .text-align {
    &-#{$align} {
      text-align: $align;
    }
  }
}

/* Font Sizes*/
$font-sizes: 12px, 14px, 16px, 18px, 20px, 24px;
@each $size in $font-sizes {
  .font-size-#{$size} {
    font-size: $size;
  }
}

$font-weights: 300, 400, 500, 600, 700;
@each $weight in $font-weights {
  .font-weight-#{$weight} {
    font-weight: $weight;
  }
}

/* Cursor */
.cursor {
  &-pointer {
    cursor: pointer;
  }
}

/* Ant Design Overrides */
.ant {
  &-card {
    border-radius: 5px;
    border: solid 1px #c4ccd0;

    &-head {
      font-family: 'Montserrat', sans-serif;
      border-bottom: transparent;
    }

    &-body {
      padding: 0 24px 24px 24px;
    }
  }

  &-btn {
    border-radius: 20px;

    &-primary {
      background-color: #2979ff;
    }

    &-link {
      box-shadow: 0px 0px 0px;
    }

    &-gray {
      border-color: rgb(133, 133, 133);
      color: rgb(133, 133, 133);
    }
  }

  &-menu-item {
    .anticon {
      font-size: 18px;
      margin-right: 20px;
    }
  }

  &-menu-submenu-title {
    .anticon {
      font-size: 18px;
      margin-right: 20px;
    }
  }

  &-menu-sub.ant-menu-inline {
    background-color: rgb(244, 244, 244);
  }

  &-table-thead {
    th.ant-table-column-has-sorters {
      background-color: #fff;
    }

    > tr > th {
      color: #444;
      background-color: white;
      border-bottom: solid 1px #c4ccd0;
    }
  }

  &-table-tbody {
    color: #444;

    > tr > td {
      background-color: #fff;
      border-bottom: solid 1px #c4ccd0;
    }

    > tr.ant-table-row:hover > td {
      background-color: #e6f7ff;
      transition: 1s;
    }
  }

  &-table-column {
    &-sorter-down {
      color: #c4ccd0;
    }
    &-sorter-down.active {
      color: #2889eb;
    }
  }

  &-pagination-item,
  &-pagination-item-active {
    font-family: 'Montserrat', 'sans-serif';
    color: #2889eb;
    border-color: transparent !important;

    :hover {
      border-color: transparent;
    }

    :focus {
      border-color: transparent;
    }
  }

  .datatable-pagination &-pagination-next,
  .datatable-pagination &-pagination-prev {
    display: none;
  }

  .datatable-control &-pagination-item,
  .datatable-control &-pagination-jump-next,
  .datatable-control &-pagination-jump-prev {
    display: none;
  }

  &-pagination-prev,
  &-pagination-next {
    background-color: #2889eb;
    color: white;
    border-radius: 5px;
    margin: 0 2px !important;
    line-height: 1;
  }

  &-pagination.mini &-pagination-prev &-pagination-item-link,
  &-pagination.mini &-pagination-next &-pagination-item-link {
    background-color: #2889eb;
    color: white;
    border-radius: 5px;
    line-height: 1;
  }

  &-input,
  &-input-affix-wrapper {
    border-radius: 10px;
  }

  &-btn {
    border-radius: 10px;
  }

  &-descriptions-bordered &-descriptions-item-label {
    padding-left: 0;
  }

  &-descriptions-bordered &-descriptions-view {
    border: none !important;
  }

  &-descriptions-bordered &-descriptions-item-label {
    background-color: transparent;
  }

  &-descriptions-bordered &-descriptions-item-label,
  &-descriptions-bordered &-descriptions-item-content {
    border: none;
  }

  &-modal {
    &-content {
      border-radius: 10px;
    }

    &-header {
      border-radius: 10px;
      border-bottom: 0px;
    }

    &-body {
      padding: 0;
    }
  }
}

.statistic-card {
  .ant-comment-inner {
    padding: 0;
  }

  .ant-comment-author {
    font-size: 14px;
  }

  .ant-comment-content {
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    font-size: 34px;
    line-height: 1;
  }

  .ant-comment-avatar {
    width: 64px;
    height: 64px;
  }
}

.fisherman-report-fishes-wrapper {
  .fisherman-report-fishes-item {
    border: 1px solid #dedede;
    padding: 8px 24px 8px 24px;
    border-radius: 4px;
    margin-bottom: 24px;
  }

  .fisherman-report-fishes-item:last-child {
    margin-bottom: 0px;
  }
}

.nav-right-menu.ant-menu-dark.ant-menu-horizontal {
  background-color: transparent;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent;
}

/* Slick Overrides */
.slick-slider {
  padding: 0 10px !important;
}

.slick-prev,
.slick-next {
  background-color: #2889eb !important;
  border-radius: 5px;
  width: 32px !important;
  height: 32px !important;
}

.slick-prev:before {
  font-family: 'Quicksand', 'sans-serif' !important;
  font-weight: 600;
  color: white !important;
  line-height: 0.5 !important;
  content: '<' !important;
}

.slick-next:before {
  font-family: 'Quicksand', 'sans-serif' !important;
  font-weight: 600;
  color: white !important;
  line-height: 0.5 !important;
  content: '>' !important;
}

.svg-fill {
  &-purple {
    fill: #9c27b0;
  }

  &-blue {
    fill: #2196f3;
  }

  &-green {
    fill: #8bc34a;
  }

  &-amber {
    fill: #ffc107;
  }

  &-pink {
    fill: #e91e63;
  }
}
